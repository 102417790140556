<template>
  <section class="projects container align-center py-12">
    <div class="row pb-4">
      <div v-for="pr in projects" class="col-12">
        <div class="project">
          <div
            class="pr-header"
            @click="$router.push('project/' + pr.act_project_id)"
          >
            <div class="pr-logo">
              <v-avatar color="primary" size="36">
                <span class="white--text headline">
                  {{ nameReduce(pr.project_name) }}
                </span>
              </v-avatar>
            </div>
            <div class="pr-title">
              <h3>{{ pr.project_name }}</h3>
              <p>
                #{{ pr.act_project_id }} / <span>{{ pr.start_date | date("DD MMM YYYY ") }}</span>
              </p>
              <div class="pr-status">
                <span :style="{ background: '#' + pr.cfd_project_status_color }"
                  >{{ pr.cfd_project_status_label }}
                </span>
              </div>
            </div>
          </div>
          <div class="pr-teams">
            <template v-if="pr.members && pr.members[0]">
              <div class="team-member" v-for="m in pr.members">
                <v-avatar color="success" size="36">
                  <span class="white--text headline">
                    {{ nameReduce(m[1]) }}
                  </span>
                </v-avatar>
                <div class="title">
                  <div class="fullname">{{ m[1] }}</div>
                  <div class="jobtitle">{{ m[2] }}</div>
                </div>
              </div>
            </template>
            <div v-else class="member-container">
              <div class="team-member">
                <v-avatar color="success" size="36">
                  <span class="white--text headline">
                    {{ nameReduce(pr.rel_record_owner_label) }}
                  </span>
                </v-avatar>
                <div class="title">
                  <div class="fullname">{{ pr.rel_record_owner_label }}</div>
                  <div class="jobtitle">{{ $t("Project Manager") }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="last-update" v-show="pr.last_updated_on">
            <h3>{{ $t("Last Update") }} ( {{ pr.last_updated_on | date }} )</h3>
            <div class="box">
              {{ pr.last_update }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { ProjectProxy } from "@/proxies/ProjectProxy";

export default {
  name: "Projects",
  data() {
    return {
      headers: [
        {
          text: "#",
          align: "end",
          value: "record_id",
        },
        {
          text: "Subject",
          align: "start",
          value: "subject",
        },
        { text: "Status", value: "case_status" },
        { text: "Label", value: "cfd_case_status_label", sortable: false },
        { text: "Created", value: "created_on" },
        { text: "Last Update", value: "last_message_date" },
      ],
      projects: [],
    };
  },
  created() {
    this.getProjects();
  },
  methods: {
    getProjects() {
      new ProjectProxy()
        .setParameters({ detailed: 1 })
        .all()
        .then((response) => {
          this.projects = response.data.map((k) => {
            k.members = k.members;
            return k;
          });
        });
    },
    getColor(calories) {
      if (calories > 3) return "red";
      else if (calories > 2) return "orange";
      else return "green";
    },
    nameReduce(user) {
      if (user) {
        let s = user.split(" ");
        console.log("s", s);
        let a = s[0] ? s[0][0] : "";
        a = s[1] && s[1][0] && s[1][0] != "-" ? a + s[1][0] : a;
        a = s[2] && s[2][0] && s[2][0] != "-" ? a + s[2][0] : a;
        return a;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.project {
  border-radius: 6px;
  padding: 10px;
  border: 1px solid #f9f9f9;
  border-top: 4px solid #2267ce;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background: #fff;
}
.last-update {
  h3 {
    color: #91a0b4;
    font-size: 14px;
    font-weight: 600;
    line-height: 3;
  }
  .box {
    background: #f0f0f0;
    color: #242424;
    font-size: 12px;
    border-bottom: 3px solid #2887eb;
    border-radius: 0.35em;
    padding: 15px;
  }
}
.pr-teams {
  width: 100%;
  padding-top: 10px;
  display: block;
  position: relative;
  border-bottom: 1px solid #efefef;
  width: 100%;

  .team-member {
    display: inline-flex;
    width: fit-content;
    min-width: 245px;
    position: relative;
    margin-bottom: 12px;
    border-right: 1px solid #d9e1ee;
    padding-left: 20px;

    .headline {
      font-size: 1rem !important;
    }
    &:first-child,
    &:nth-child(6),
    &:nth-child(10) {
      padding-left: 0px;
      .team-member {
        padding-left: 0px;
      }
    }
    &:nth-child(5),
    &:nth-child(10) {
      border-right: 0px solid #d9e1ee;
      .team-member {
        border-right: 0px solid #d9e1ee;
      }
    }
    .title {
      width: fit-content;
      margin-left: 10px;
      overflow: hidden;
      white-space: nowrap;
      .fullname {
        font-size: 14px;
        color: #201f1e;
        line-height: 1.5;
        padding-right: 6px;
      }
      .jobtitle {
        font-size: 12px;
        color: #605e5c;
        line-height: 1.3;
      }
      .divider-border {
        border-right: 1px solid #afafaf;
        position: absolute;
        right: 3px;
        height: 25px;
        top: 10px;
      }
    }
  }
}

.pr-header {
  width: 100%;
  padding-top: 10px;
  display: inline-flex;
  position: relative;
  border-bottom: 1px solid #efefef;
  width: 100%;
  cursor: pointer;
  .pr-logo {
    width: 60px;
    .headline {
      font-size: 1rem !important;
    }
  }
  .pr-title {
    h3 {
      color: #14142b;
      font-weight: 600;
    }
    p {
      font-size: 14px;
      font-weight: 500;
      color: #14142b;
      span {
        color: #91a0b4;
        font-size: 12px;
        font-size: 12px;
      }
    }
    width: 100%;
  }
  .member-container {
    padding: 0px 0px 10px 0px;
  }
  .pr-status {
    position: absolute;
    top: 10px;
    right: 10px;

    span {
      border-radius: 5px;
      padding: 4px;
      color: #fff;
    }
  }
}
</style>
